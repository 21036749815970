body {
  font-family: "Playfair Display", serif;
  font-size: $default-font-size;
  color: $color-tertiary-2;
}

p {
  color: $color-tertiary-1;
  font-size: $default-font-size;
}

.case-p {
  font-size: 2.6rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.9rem;
  color: $color-primary-light;
}

.policy {
  font-size: 1.1rem;
  text-align: center;
}
.heading-faq {
  font-size: 5rem;
  font-weight: bold;
  opacity: 0.7;
  background-color: $color-primary-light;
  color: $color-tertiary-1;
}

.samples {
  font-size: 1.5rem;
  padding-top: 3rem;
  text-align: center;

  @media only screen and (min-width: $bp-medium) {
    font-size: 3rem;
  }
}

h1 {
  font-size: 3rem;
  color: $color-tertiary-1;
  font-weight: bold;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: auto;
}

.contact {
  font-size: 3rem;
  padding-top: 7rem;
  padding-bottom: 7rem;
  color: $color-primary-light;
  margin-top: 1.8rem;
  margin: auto;
}

.asterisk {
  color: #b22222;
  font-size: 2.5rem;
}

h2 {
  display: block;
  font-size: 3rem;
  font-weight: bold;
  margin-left: 3rem;
  //background: linear-gradient(90deg, #ff7a18, #af002d 70%);
}

@media only screen and (min-width: $bp-medium) {
  h2 {
    font-size: 5rem;
  }
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  color: $color-primary-light;
  padding: 1.3rem;
  margin-bottom: 2rem;
  max-width: 100%;
  @media only screen and (min-width: $bp-medium) {
    font-size: 2.2rem;
    padding: 2rem;
  }
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: auto;
  height: 13rem;
  width: 13rem;
  border-radius: 50%;
  background-color: $color-tertiary-2;
  margin-top: 3rem;

  @media only screen and (min-width: $bp-medium) {
    height: 15rem;
    width: 15rem;
  }
}

.heading-wrapper:nth-child(1),
.heading-wrapper:nth-child(3) {
  background-color: $color-secondary;
}

.heading-wrapper h3,
.heading-wrapper-alt h3 {
  justify-self: center;
  align-self: center;
  margin: auto;
  color: $color-primary-dark;
}

.heading-cta {
  padding-top: 3rem;
  font-size: 3rem;
  font-weight: bold;
  color: $color-primary-light;
  text-align: center;

  @media only screen and (min-width: $bp-medium) {
    font-size: 3.2rem;
    padding-top: 5rem;
  }
}

.cta-p {
  color: $color-primary-light;
  font-size: 2rem;
  text-align: center;
  align-items: center;
  margin: auto;
  padding: 3rem;

  @media only screen and (min-width: $bp-medium) {
    font-size: 2.6rem;
  }
}

.services {
  padding: 4rem 2rem 4rem 2rem;
  text-align: center;
  border: 0.1px solid $color-primary-light;
  border-bottom: 0;
  border-top: 0;
}

.cta {
  background-color: $color-primary-light;
  color: $color-tertiary-1;
}
.heading-tertiary {
  display: block;
  text-align: center;
  padding: 7rem;
}

.heading-portfolio {
  display: block;
}

.heading-container {
  padding-top: 1.5rem;
}

.heading-2 {
  text-align: center;
  margin: auto;
  font-size: 3.8rem;

  @media all and (min-width: $bp-medium) {
    width: 40%;
    font-size: 4rem;
    padding: 0.5rem;
  }
}

.heading-3 {
  width: 80%;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 2;
  margin: auto;
}

.seo-heading {
  flex-direction: column;
  padding: 3rem;
  text-align: center;
  color: $color-tertiary-2;
  text-align: center;
  letter-spacing: 3px;
  margin: auto;

  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
    flex-wrap: nowrap;
    writing-mode: vertical-lr;
    width: 10%;
    font-size: 3rem;
    height: auto;
  }
}

h4 {
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 2rem 2.2rem;
  color: $color-tertiary-2;
  font-size: 2.7rem;
  font-weight: bold;
  text-align: center;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  @media only screen and (min-width: $bp-medium) {
    font-size: 3.3rem;
  }
}

.heading-tagline {
  font-size: 3rem;

  @media only screen and (min-width: $bp-medium) {
    font-size: 4.5rem;
  }
}
.heading-seo {
  font-size: 1.5rem;
  @media only screen and (min-width: $bp-medium) {
    font-size: 1.8rem;
  }
}

.before {
  display: inline-flex;
  margin-top: 5%;
}

p {
  padding: 2rem;
  line-height: 2;
}

span {
  padding-bottom: 3rem;
}

.card_heading {
  font-family: map-roman-narrow, serif;
  font-weight: 400;
  font-style: normal;

  color: $color-primary-light;
}

.card_title {
  font-size: 1.9rem;
  font-weight: bold;
}

.heading-desc {
  color: $color-tertiary-1;
  font-size: 1.7rem;
  @media only screen and (min-width: $bp-medium) {
    font-size: 2.2rem;
  }
}

.highlight,
.highlight--2 {
  position: relative;
  z-index: 1;
}

.highlight::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 1rem;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  z-index: -1;
  transform: scale(1, 0.4) skewX(-8deg);
  background-color: $color-secondary;
}

.highlight--2::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0.5rem;
  left: 5px;
  right: 5px;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  z-index: -1;
  transform: scale(1, 0.8) skewX(-8deg);
  background-color: $color-secondary;
}

blockquote p {
  font-size: 3rem;
  font-family: "Courier New", Courier, monospace;
  margin: auto;
  width: 90vw;
  padding: 8rem;
}

cite {
  display: block;
  margin: auto;
  padding: 3rem;
  line-height: 3;
  text-align: center;
  font-size: 1.8rem;
  font-style: italic;
}

blockquote {
  font-weight: 100;
  font-size: 2rem;
  line-height: 1.4;
  position: relative;
  width: 90vw;
  max-width: 90%;
  margin: auto;
}

blockquote::before,
blockquote::after {
  position: absolute;
  color: rgba(228, 107, 232, 1);
  font-size: 8rem;
  width: 2rem;
  height: 2rem;
}

blockquote::before {
  content: "“";
  left: 1rem;
  top: 1rem;

  @media only screen and (min-width: $bp-medium) {
    left: 14rem;
    top: -3rem;
  }
}

blockquote::after {
  content: "”";
  right: 1rem;
  top: 2rem;

  @media only screen and (min-width: $bp-medium) {
    right: 20rem;
    bottom: 2rem;
  }
}

.underline-effect {
  display: inline-block;
  position: relative;
}

@media only screen and (min-width: $bp-medium) {
  .underline-effect::after {
    content: "";
    position: absolute;
    width: 70%;
    transform: scaleX(0);
    height: 8px;
    bottom: 0;
    left: 0;
    background: $color-gradient;
    transform-origin: bottom right;
    transition: transform 0.35s ease-in;
    transform: scaleX(0) skew(-5deg);
  }
}

.underline-effect:hover::after {
  transform: scaleX(1) skew(-5deg);
  transform-origin: bottom left;
}

.black-gradient {
  background: $color-gradient-2;

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
}
