.privacy {
  width: 100vw;
  max-width: 100%;
  margin: auto;
  background-color: $color-primary-dark;
  padding: 2rem;

  @media only screen and (min-width: $bp-medium) {
    padding-top: 10rem;
    padding-left: 3rem;
  }
}

.privacy h1:first-child {
  font-size: 2.5rem;
  text-align: center;
}

.privacy h1 {
  text-align: left;
  padding-top: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  font-size: 2rem;
  text-decoration: underline;
  color: $color-tertiary-1;
  opacity: 0.8;
}

.privacy p {
  text-align: left;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.6rem;
  line-height: 2.4;
  color: $color-tertiary-1;

  @media only screen and (min-width: $bp-medium) {
    font-size: 1.8rem;
  }
}

.privacy address {
  padding-top: 5rem;
  text-align: center;
  line-height: 2.4;
}

.privacy address a {
  color: $color-secondary;
}

.privacy address span {
  text-decoration: underline;
}
hr {
  height: 1px;
  background-color: #ff9400;
  border: none;
  width: 70%;
  opacity: 0.5;
  margin: auto;
}
