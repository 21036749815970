footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100%;
  width: 100%;
  height: auto;
  background-color: $color-tertiary-1;
  color: $color-primary-light;

  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
    height: 50rem;
  }
}

.footer__menu {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.footer-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer-col-3 {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.social-media {
  margin: auto;
  padding-bottom: 1.2rem;
}

.social-media svg {
  @media only screen and (max-width: $bp-medium) {
    width: 17px;
    height: 17px;
  }
}

footer a {
  color: $color-primary-light;
  font-size: 1.3em;
}

.footer-p {
  color: $color-primary-light;
  text-align: center;
}

.footer-col-1 {
  margin: auto;
}

.footer-col-2 {
  margin: auto;
  text-align: center;
}

.footer-col-2 li {
  display: inline-block;
  list-style: none;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.footer-col-3 {
  margin: auto;
  text-align: center;
}

.footer-col-3 li {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
}

.footer-col-3 h6 {
  text-transform: uppercase;
}

.logo-light {
  text-align: center;
}
