.btn {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px 7px;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 11rem;
  border: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  @media only screen and (min-width: $bp-medium) {
    padding: 14px 12px;
    width: 14rem;
  }

  &--1 {
    background-color: $color-secondary;
    color: $color-tertiary-2;
    border-radius: 25px;
    padding: 1.3rem 1.4rem;
    font-size: 1.2rem;

    @media only screen and (min-width: $bp-medium) {
      padding: 1.5rem 1.7rem;
      font-size: 1.6rem;
    }
  }

  &--2 {
    background-color: $color-primary-light;
    color: $color-tertiary-2;
    padding: 1.7rem 1.9rem;
    font-size: 1.4rem;
    width: 16rem;
    border-radius: 26px;
    margin-top: 3rem;
    text-align: center;

    @media only screen and (min-width: $bp-medium) {
      padding: 2rem 2.2rem;
      font-size: 2rem;
      width: 20rem;
      margin-top: 5rem;
    }
  }

  &--3 {
    width: 13rem;
    height: 6rem;
    font-size: $default-font-size;
    padding: 1.5rem 1.8rem;
    font-family: "Playfair Display", serif;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 7rem;
    color: black;
    background-color: #fff;
    z-index: 2;

    @media only screen and (min-width: $bp-medium) {
      width: 150px;
      height: 60px;
      margin-top: 6rem;
      padding: 2rem 2.4rem;
    }
  }

  &--5 {
    position: absolute;
    bottom: 50px;
    right: 30px;
    background-color: $color-secondary;
    color: $color-tertiary-2;
    border-radius: 12px;
    padding: 1.3rem 1.4rem;
    font-size: 1.2rem;
    z-index: 2;

    @media only screen and (min-width: $bp-medium) {
      padding: 1.5rem 1.7rem;
      font-size: 1.6rem;
    }
  }
}

.btn:hover,
.btn:active,
.btn:focus {
  background-color: $color-secondary;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

  &.btn a:hover {
    color: $color-primary-light;
  }

  &.btn--3:hover {
    background-color: $color-gradient-2;
    border: none;
    color: $color-primary-light;
  }

  &.btn--2:hover {
    color: $color-primary-light;
    background-color: $color-gradient-2;
  }

  &.btn--1:hover {
    color: $color-tertiary-1;
    background-color: $color-primary-light;
  }

  &.btn--5:hover {
    color: $color-primary-light;
    background: $color-gradient;
  }
}
