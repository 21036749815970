.contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  background-color: $color-primary-dark;
}

.contact-container p {
  text-align: left;
}
.contact a {
  font-size: 1.4rem;
  color: $color-tertiary-1;
  text-decoration: underline;
  margin-left: 2rem;
}

.social_link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
  margin-left: 2rem;
  gap: 1rem;
}

.drop-the-line {
  height: auto;
  width: 100vw;
  max-width: 100%;
  background: $color-tertiary-1;
  @media only screen and (min-width: $bp-medium) {
    padding-top: 10rem;
  }
}

.drop-the-line p {
  max-width: 90%;
  font-size: 2rem;
  color: $color-primary-light;
  margin: auto;
  padding-bottom: 5rem;
  font-size: 1.6rem;

  @media only screen and (min-width: $bp-medium) {
    width: 60%;
  }
}

.drop-the-line h1 {
  padding-top: 3rem;
  color: $color-primary-light;
  font-size: 3rem;
}

.drop-the-line h3 {
  font-size: 2rem;
}
