.dev-services {
  width: 100vw;
  max-width: 100%;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex: 2 1 auto;

  height: auto;
  background-color: $color-primary-light;

  @media only screen and (min-width: 959px) {
    flex-direction: row;
    padding-bottom: 1rem;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.dev-services p {
  max-width: 100%;
  width: 100%;
  background-color: #f2eded;
}

.dev-services-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 100%;
  width: 100vw;
  height: auto;
  align-content: center;

  @media only screen and (min-width: 959px) {
    flex-direction: row;
    justify-content: center;
  }
}

.col-first,
.col-second,
.col-third,
.col-fourth {
  @media only screen and (min-width: 959px) {
    flex-grow: 2;
    width: 25%;
    max-width: 25%;
    height: auto;
    max-height: 50rem;
    margin-bottom: 3rem;
  }
}
.col-first > p,
.col-second > p,
.col-third > p,
.col-fourth > p {
  margin-top: 3rem;
  height: 100%;
  color: $color-primary-dark;
  background-color: $color-tertiary-1;
  width: 90vw;
  max-height: 100%;
  text-align: left;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-radius: 9px;
}

.description {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-color: $color-primary-dark;
  @media only screen and (min-width: 959px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.col-dev {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 2rem;
  padding-top: 3rem;
  padding: 2rem;

  @media only screen and (min-width: 959px) {
    width: 55%;
  }
}

figure {
  text-align: center;
  padding-top: 2rem;
}

svg {
  display: inline-block;
  margin: auto;
  height: 100%;
}

.ul-dev {
  list-style: none;
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
}

.ul-dev li {
  list-style: none;
  padding: 1.5rem 1.9rem;
}

.li-dev {
  font-size: 1.4rem;

  @media only screen and (min-width: $bp-medium) {
    font-size: $default-font-size;
  }
}

.tick-icon {
  width: 32px; /* Adjust the size to make it sharp */
  height: 32px;
  vertical-align: middle; /* Align the icon with the text */
}

.desc_img-container {
  width: 100%;
  height: 50rem;
  max-width: 100%;
  min-height: 100%;
  background: url("../images/artboard.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  @media only screen and (min-width: 959px) {
    width: 45%;

    background-position: center;
    background-repeat: no-repeat;
    margin-left: 5rem;
    opacity: 0.6;
    align-self: center;
  }
}

.seo-banner {
  min-height: auto;
  height: 30rem;
  margin-top: 0;
  padding-top: 0;
  max-width: 100%;
  width: 100vw;
  background: url("../images/seo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  @media only screen and (min-width: 959px) {
    height: 100vh;
    background: url("../images/seo-2.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -o-background-position: fixed;
    -webkit-background-position: fixed;

    -o-background-size: cover;

    background-size: cover;
  }
}

.seo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 2rem;
  color: #333333;
  background-color: $color-primary-light;
  @media only screen and (min-width: 959px) {
    flex-direction: row;
  }
}

.seo-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100vw;
  max-width: 100%;
  background-color: $color-primary-dark;

  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
    width: 100vw;
    max-width: 100%;
    gap: 3rem;
  }
}

.seo img {
  justify-content: center;
  transform: translateY(-10%);
  transition: all 2s ease-in;
  width: 100px;
}

.img-wrapper {
  margin: auto;
  justify-content: center;
  align-items: center;
}
