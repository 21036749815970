/* Header styling */
header {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  flex: 1 0 auto;
  background-image: url("../images/london.jpg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

@media (min-width: $bp-medium) {
  #header-primary {
    background-position: center;
    animation: zoomPan 4s ease-out forwards;
  }
}

/* Keyframes for header background animation */
@keyframes zoomPan {
  0% {
    background-size: 130%;
    background-position: bottom left;
  }
  100% {
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and (max-width: 1115px) {
  #header-primary {
    position: relative;
    z-index: 1;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    padding: 0.5rem;
    margin: 0.5rem;
    width: calc(100vw - 1rem);
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    //clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);

    /* Apply background overlay */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      pointer-events: none;
      z-index: -1;
    }
  }
}
/* Wrapper setup to cover header */
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: $color-primary-light;
  background-color: $color-tertiary-1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(100vh);
  transition: transform 1s ease-out, opacity 0.5s ease-out;
  overflow: hidden;
}

.header-secondary {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  flex: 1 0 auto;
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
    z-index: 1;
  }

  @media only screen and (max-width: 1115px) {
    .header-secondary {
      border-top-left-radius: 17px;
      border-top-right-radius: 17px;
      padding: 0.5rem;
      margin: 0.5rem;
      width: calc(100vw - 1rem);
      //clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    }
  }
}

.header-tertiary {
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  flex: 1 0 auto;
  background-image: url("../images/london-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
    z-index: 1;
  }
}

@media only screen and (max-width: 1115px) {
  .header-tertiary {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    padding: 0.5rem;
    margin: 0.5rem;
    width: calc(100vw - 1rem);
    //clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }
}

.wrapper.slide-up {
  opacity: 1;
  transform: translateY(0);
}

/* Navigation visibility control */
header nav {
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

header nav.nav-fixed {
  background-color: #f8f2ee !important;
  border-bottom: 0.5px solid #d3d3d3;
}

.wrapper-visible #header-primary nav {
  opacity: 0;
}

.nav-fixed {
  position: fixed;
  transition: background-color 0.3s ease;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

header.header-quaternary {
  background-image: none;
  height: auto;
  width: 100vw;
  max-width: 100%;
}

/* Initial styles for the hero section */
.hero {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 4s ease-out, transform 4s ease-out;
  width: 85%;
  max-width: 100%;
  margin: auto;
}

.hero.show {
  opacity: 1;
  transform: translateY(0);
}

.hero p {
  text-align: center;
  color: $color-primary-light;
  line-height: 2.2;
}

.hero .slogan {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.6;
  font-size: $default-font-size;
}

@media only screen and (min-width: $bp-medium) {
  .hero .slogan {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: $bp-medium) {
  .hero .short-intro p {
    font-size: 2.5rem;
  }
}

@media (min-width: $bp-medium) {
  .start-intro {
    display: none;
  }
}

@media (max-width: $bp-medium) {
  .start-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    margin-left: 1.5rem;
    opacity: 0;
    text-align: center;
    height: 100vh;
    transition: opacity 1s ease-in-out;
  }
}

.start-intro p {
  position: absolute;
  top: 10rem;
  width: 80%;
  margin: auto;
  text-align: left;
  line-height: 1.9;
  color: $color-primary-light;
  font-size: 2rem;
  font-weight: 400;
  z-index: 1000;
}

.start-intro .btn {
  position: absolute;
  bottom: 3rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $color-secondary;
  border: none;
  color: $color-primary-light;
  width: 14rem;
  height: 5rem;
}

.start-intro .btn:hover {
  background-color: $color-tertiary-1;
}
