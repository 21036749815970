.tagline {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1 1 auto;
  min-height: 50vh;
  height: auto;
  width: 100%;
  max-width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $color-primary-light;
  color: $color-tertiary-1;
}
.tagline-content {
  flex: 1;
  justify-content: center;
  align-self: center;
}

.tagline h1 {
  font-size: 3rem;
  width: 90%;
  max-width: 90%;
  @media only screen and (min-width: $bp-medium) {
    font-size: 4.5rem;
  }
}

.tagline p {
  width: 90%;
  max-width: 90%;
  @media only screen and (min-width: $bp-medium) {
    width: 75%;
    max-width: 75%;
  }
}
.promo {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  background-color: $color-primary-light;
  color: $color-tertiary-1;
  height: auto;
  font-size: 2rem;
  line-height: 2.3;
  margin: auto;
  align-items: center;

  @media all and (min-width: $bp-medium) {
    width: 100%;
    height: auto;
    font-size: 3rem;
    line-height: 1.5;
  }
}

.portfolio-sample-1,
.portfolio-sample-2,
.portfolio-sample-3,
.portfolio-sample-4 {
  max-width: 100%;
  width: 100vw;
  height: auto;
  border: none;
  margin: auto;

  @media only screen and (min-width: $bp-medium) {
    flex: 1 1 100%;
    flex-wrap: nowrap;
    width: 100%;
  }
}

.portfolio-sample-1 {
  background-color: $color-primary-dark;
}

.num__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  margin: 3rem;
  border-radius: 50%;
  color: #f8f2ee;
  border: 6px solid #8136e3;
  background: $color-gradient;

  @media only screen and (min-width: $bp-medium) {
    width: 13rem;
    height: 13rem;
    border: 12px solid #8136e3;
  }
}

.num__container--light {
  border-color: #ee5a24;
  background: $color-gradient-2;
}

.samples {
  padding: 0;
  width: 100vw;
  max-width: 100%;
}

section {
  z-index: 100;
}
.portfolio-sample-1 {
  border: none;
}

.portfolio-sample-2 {
  border: none;
  background-color: $color-primary-dark;
}

.portfolio-sample-3 {
  border: none;
}

.portfolio-sample-5,
.portfolio-sample-6,
.portfolio-sample-7,
.portfolio-sample-8,
.portfolio-sample-9 {
  height: 300px;
}

@media only screen and (min-width: $bp-medium) {
  .portfolio-sample-5,
  .portfolio-sample-6,
  .portfolio-sample-7,
  .portfolio-sample-8,
  .portfolio-sample-9 {
    min-height: 800px;
    width: 100vw;
    max-width: 100%;
  }
}

.portfolio-sample-5,
.portfolio-sample-6,
.portfolio-sample-7,
.portfolio-sample-1,
.portfolio-sample-2,
.portfolio-sample-3,
.portfolio-sample-4,
.portfolio-sample-8,
.portfolio-sample-9 {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.portfolio-sample-5 {
  background-image: url("../images/dev-sample-1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.portfolio-sample-6 {
  background-image: url("../images/dev-sample-2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.portfolio-sample-7 {
  background-image: url("../images/dev-sample-3.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.portfolio-sample-8 {
  background-image: url("../images/design-1.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-primary-light;
}

.portfolio-sample-9 {
  background-image: url("../images/design-2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color-primary-light;
}

.samples img {
  width: 80%;
}

.portfolio {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  background-color: $color-primary-dark;
  color: $color-tertiary-1;

  @media only screen and (min-width: $bp-medium) {
    height: 600px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
  }
}

.container {
  background: url("../images/profile-pic.jpeg") no-repeat center;
  background-size: cover;
  background-position: top;
  opacity: 0.7;

  // On mobile screens
  @media only screen and (max-width: $bp-medium) {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-size: cover;
    margin: 0 auto;
    margin-top: 3rem;
    background-position: top;
  }

  // On larger screens
  @media only screen and (min-width: $bp-medium) {
    border-radius: 0;
    width: 400px;
    height: 600px;
    max-height: auto;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $bp-medium) {
    width: 500px;
    height: 800px;
    justify-content: center;
    align-items: center;
  }
}

.portfolio p {
  margin: 0;
  font-size: $default-font-size;
  color: $color-tertiary-1;
  line-height: 1.9;

  @media only screen and (min-width: $bp-medium) {
    font-size: 1.8rem;
  }
}

.desktop,
.tablet,
.phone {
  align-items: center;
  max-width: 100%;
  height: auto;
}

.desktop {
  width: 800px;
}

.phone {
  height: 60rem;
  width: auto;
}

.tablet {
  width: 100rem;
}

.phone {
  align-items: center;
}

.intro {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100vw;
  height: auto;
  align-self: center;
  background-color: $color-primary-dark;
  color: $color-tertiary-1;

  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
  }
}

.intro p {
  background-color: $color-primary-dark;
  width: 90vw;
  max-width: 100%;
  padding: 2rem;
  font-size: 2rem;

  margin: auto;

  @media only screen and (min-width: $bp-medium) {
    width: 75%;
    max-width: 75%;
    font-size: 1.9rem;
    line-height: 3.3;
  }
}

.introduction-container {
  margin: auto;
  width: 100vw;
  max-width: 100%;

  height: auto;
  padding: 4rem;
}
.introduction-container p {
  padding-bottom: 2rem;
  padding: 2rem;
  font-size: 2rem;
  width: 90%;
  background-color: $color-primary-dark;
  color: $color-tertiary-1;
  line-height: 1.9;
  font-style: italic;
}

.banner {
  display: flex;
  height: 20rem;
  max-width: 100%;
  width: 100vw;
  justify-content: center;
  background: url("../images/bckgpic-sm.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -o-background-size: contain;
  -webkit-background-size: contain;

  @media only screen and (min-width: $bp-medium) {
    height: 100vh;

    background: url("../images/bckgpic-lg.png") no-repeat center center fixed;
    -o-background-position: fixed;
    -webkit-background-position: fixed;
  }
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 80%;
  height: auto;
  margin: 1rem auto;
  margin-bottom: 5rem;
}

.faq h5 {
  text-align: left;
}

.faq-content {
  height: auto;
  width: 100%;
  max-width: 100%;
  padding: 0 auto;
  border-bottom: 0.1px solid #3730a3;
}

.faq-content div {
  display: block;
  margin: auto;
  margin-top: 10%;

  @media only screen and (min-width: $bp-medium) {
    margin-top: 5%;
  }
}
.acc-btn {
  width: 100%;
  padding: 1.6rem 2rem;
  font-size: 1.6rem;
  cursor: pointer;
  background: inherit;
  border: none;
  outline: none;
  text-align: right;
  transition: all 0.5s linear;
}

.acc-btn i {
  background-image: linear-gradient(
    109.6deg,
    rgba(228, 107, 232, 1) 11.2%,
    rgba(87, 27, 226, 1) 96.7%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.answer {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-bottom: 0;
  font-size: $default-font-size;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

fieldset {
  color: $color-tertiary-1;
  border: none;
}

legend {
  margin: 0 auto;
}

.our-story {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  padding: 4rem;
  font-size: 1.3em;
  color: $color-tertiary-1;

  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
  }
}

.our-story p {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: $color-tertiary-1;
  text-align: left;
}

//Scroll Effect
.box {
  transition: transform 1.3s, opacity 1.3s;
  margin-left: 5%;
}

.box.left {
  transform: translateX(-100px);
  opacity: 0;
}

.box.right {
  transform: translateX(100px);
  opacity: 0;
}

.box.active {
  transform: translateX(0);
  opacity: 1;
}

.scroll-reveal.active {
  opacity: 1;
}

@media only screen and (min-width: $bp-medium) {
  .box {
    margin-left: calc(50% - 700px);
    transition: transform 1.3s, opacity 1.3s;
  }
  .box.left {
    transform: translateX(-700px);
    opacity: 0;
  }

  .box.right {
    transform: translateX(200px);
    opacity: 0;
  }

  .box.active {
    transform: translateX(0);
    opacity: 1;
  }
}
