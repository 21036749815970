.demo-1 {
  text-align: center;
  z-index: 10;
  color: #f2eded;
  text-transform: uppercase;
  opacity: 1;
  margin: auto;
  padding: 2rem;
  font-family: Playfair Display\, serif;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  transition: opacity 1s ease-out;
  position: relative;
  top: 50%;
}

.demo-1.fade-out {
  display: none;
}

@media only screen and (width >= 1116px) {
  .demo-1 {
    padding-left: 4rem;
    font-size: 7rem;
    line-height: 2.2;
    top: 50%;
  }
}

.scroll-reveal.opacity-only {
  opacity: 0;
  transition: opacity 2s ease-out;
}

.scroll-reveal.opacity-only.reveal {
  opacity: 1;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: #f8f2ed;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #f8f2ed;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
  border: 1px solid #f8f2ed;
  border-radius: 10px;
}

::selection {
  color: #000;
  background-color: #ff9400;
}

body {
  color: #000;
  font-family: Playfair Display, serif;
  font-size: 1.6rem;
}

p {
  color: #28231d;
  font-size: 1.6rem;
}

.case-p {
  font-size: 2.6rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  text-align: center;
  color: #f8f2ed;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.9rem;
}

.policy {
  text-align: center;
  font-size: 1.1rem;
}

.heading-faq {
  opacity: .7;
  color: #28231d;
  background-color: #f8f2ed;
  font-size: 5rem;
  font-weight: bold;
}

.samples {
  text-align: center;
  padding-top: 3rem;
  font-size: 1.5rem;
}

@media only screen and (width >= 1116px) {
  .samples {
    font-size: 3rem;
  }
}

h1 {
  color: #28231d;
  text-align: center;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 3rem;
  font-weight: bold;
}

.contact {
  color: #f8f2ed;
  margin: auto;
  padding-top: 7rem;
  padding-bottom: 7rem;
  font-size: 3rem;
}

.asterisk {
  color: #b22222;
  font-size: 2.5rem;
}

h2 {
  margin-left: 3rem;
  font-size: 3rem;
  font-weight: bold;
  display: block;
}

@media only screen and (width >= 1116px) {
  h2 {
    font-size: 5rem;
  }
}

h3 {
  text-align: center;
  color: #f8f2ed;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-bottom: 2rem;
  padding: 1.3rem;
  font-size: 1.5rem;
  display: flex;
}

@media only screen and (width >= 1116px) {
  h3 {
    padding: 2rem;
    font-size: 2.2rem;
  }
}

.heading-wrapper {
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  place-items: center;
  width: 13rem;
  height: 13rem;
  margin: 3rem auto auto;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .heading-wrapper {
    width: 15rem;
    height: 15rem;
  }
}

.heading-wrapper:first-child, .heading-wrapper:nth-child(3) {
  background-color: #ff9400;
}

.heading-wrapper h3, .heading-wrapper-alt h3 {
  color: #f2eded;
  place-self: center;
  margin: auto;
}

.heading-cta {
  color: #f8f2ed;
  text-align: center;
  padding-top: 3rem;
  font-size: 3rem;
  font-weight: bold;
}

@media only screen and (width >= 1116px) {
  .heading-cta {
    padding-top: 5rem;
    font-size: 3.2rem;
  }
}

.cta-p {
  color: #f8f2ed;
  text-align: center;
  align-items: center;
  margin: auto;
  padding: 3rem;
  font-size: 2rem;
}

@media only screen and (width >= 1116px) {
  .cta-p {
    font-size: 2.6rem;
  }
}

.services {
  text-align: center;
  border: 0;
  border-left: .1px solid #f8f2ed;
  border-right: .1px solid #f8f2ed;
  padding: 4rem 2rem;
}

.cta {
  color: #28231d;
  background-color: #f8f2ed;
}

.heading-tertiary {
  text-align: center;
  padding: 7rem;
  display: block;
}

.heading-portfolio {
  display: block;
}

.heading-container {
  padding-top: 1.5rem;
}

.heading-2 {
  text-align: center;
  margin: auto;
  font-size: 3.8rem;
}

@media (width >= 1116px) {
  .heading-2 {
    width: 40%;
    padding: .5rem;
    font-size: 4rem;
  }
}

.heading-3 {
  text-align: center;
  width: 80%;
  margin: auto;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 2;
}

.seo-heading {
  text-align: center;
  color: #000;
  text-align: center;
  letter-spacing: 3px;
  flex-direction: column;
  margin: auto;
  padding: 3rem;
}

@media only screen and (width >= 1116px) {
  .seo-heading {
    writing-mode: vertical-lr;
    flex-flow: row;
    width: 10%;
    height: auto;
    font-size: 3rem;
  }
}

h4 {
  text-align: center;
  color: #000;
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 2rem 2.2rem;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 2.7rem;
  font-weight: bold;
}

@media only screen and (width >= 1116px) {
  h4 {
    font-size: 3.3rem;
  }
}

.heading-tagline {
  font-size: 3rem;
}

@media only screen and (width >= 1116px) {
  .heading-tagline {
    font-size: 4.5rem;
  }
}

.heading-seo {
  font-size: 1.5rem;
}

@media only screen and (width >= 1116px) {
  .heading-seo {
    font-size: 1.8rem;
  }
}

.before {
  margin-top: 5%;
  display: inline-flex;
}

p {
  padding: 2rem;
  line-height: 2;
}

span {
  padding-bottom: 3rem;
}

.card_heading {
  color: #f8f2ed;
  font-family: map-roman-narrow, serif;
  font-style: normal;
  font-weight: 400;
}

.card_title {
  font-size: 1.9rem;
  font-weight: bold;
}

.heading-desc {
  color: #28231d;
  font-size: 1.7rem;
}

@media only screen and (width >= 1116px) {
  .heading-desc {
    font-size: 2.2rem;
  }
}

.highlight, .highlight--2 {
  z-index: 1;
  position: relative;
}

.highlight:after {
  content: "";
  opacity: .7;
  z-index: -1;
  background-color: #ff9400;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 1rem;
  left: 0;
  transform: scale(1, .4)skewX(-8deg);
}

.highlight--2:after {
  content: "";
  opacity: .7;
  z-index: -1;
  background-color: #ff9400;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: .5rem;
  left: 5px;
  right: 5px;
  transform: scale(1, .8)skewX(-8deg);
}

blockquote p {
  width: 90vw;
  margin: auto;
  padding: 8rem;
  font-family: Courier New, Courier, monospace;
  font-size: 3rem;
}

cite {
  text-align: center;
  margin: auto;
  padding: 3rem;
  font-size: 1.8rem;
  font-style: italic;
  line-height: 3;
  display: block;
}

blockquote {
  width: 90vw;
  max-width: 90%;
  margin: auto;
  font-size: 2rem;
  font-weight: 100;
  line-height: 1.4;
  position: relative;
}

blockquote:before, blockquote:after {
  color: #e46be8;
  width: 2rem;
  height: 2rem;
  font-size: 8rem;
  position: absolute;
}

blockquote:before {
  content: "“";
  top: 1rem;
  left: 1rem;
}

@media only screen and (width >= 1116px) {
  blockquote:before {
    top: -3rem;
    left: 14rem;
  }
}

blockquote:after {
  content: "”";
  top: 2rem;
  right: 1rem;
}

@media only screen and (width >= 1116px) {
  blockquote:after {
    bottom: 2rem;
    right: 20rem;
  }
}

.underline-effect {
  display: inline-block;
  position: relative;
}

@media only screen and (width >= 1116px) {
  .underline-effect:after {
    content: "";
    transform-origin: 100% 100%;
    background: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
    width: 70%;
    height: 8px;
    transition: transform .35s ease-in;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0)skew(-5deg);
  }
}

.underline-effect:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1)skew(-5deg);
}

.black-gradient {
  color: #0000;
  background: linear-gradient(315deg, #ea2027 0%, #ee5a24 54%) text;
  display: inline;
}

.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 3rem;
}

.u-margin-bottom-big {
  margin-bottom: 5rem;
}

.u-margin-top-small {
  margin-top: 3rem;
}

.u-margin-top-big {
  margin-top: 5rem;
}

.btn {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 11rem;
  margin: 3rem auto;
  padding: 10px 7px;
  transition: all .2s;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1116px) {
  .btn {
    width: 14rem;
    padding: 14px 12px;
  }
}

.btn--1 {
  color: #000;
  background-color: #ff9400;
  border-radius: 25px;
  padding: 1.3rem 1.4rem;
  font-size: 1.2rem;
}

@media only screen and (width >= 1116px) {
  .btn--1 {
    padding: 1.5rem 1.7rem;
    font-size: 1.6rem;
  }
}

.btn--2 {
  color: #000;
  text-align: center;
  background-color: #f8f2ed;
  border-radius: 26px;
  width: 16rem;
  margin-top: 3rem;
  padding: 1.7rem 1.9rem;
  font-size: 1.4rem;
}

@media only screen and (width >= 1116px) {
  .btn--2 {
    width: 20rem;
    margin-top: 5rem;
    padding: 2rem 2.2rem;
    font-size: 2rem;
  }
}

.btn--3 {
  color: #000;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 13rem;
  height: 6rem;
  margin-top: 2rem;
  margin-bottom: 7rem;
  padding: 1.5rem 1.8rem;
  font-family: Playfair Display, serif;
  font-size: 1.6rem;
}

@media only screen and (width >= 1116px) {
  .btn--3 {
    width: 150px;
    height: 60px;
    margin-top: 6rem;
    padding: 2rem 2.4rem;
  }
}

.btn--5 {
  color: #000;
  z-index: 2;
  background-color: #ff9400;
  border-radius: 12px;
  padding: 1.3rem 1.4rem;
  font-size: 1.2rem;
  position: absolute;
  bottom: 50px;
  right: 30px;
}

@media only screen and (width >= 1116px) {
  .btn--5 {
    padding: 1.5rem 1.7rem;
    font-size: 1.6rem;
  }
}

.btn:hover, .btn:active, .btn:focus {
  background-color: #ff9400;
  transition: all .2s;
}

.btn:hover.btn a:hover, .btn:active.btn a:hover, .btn:focus.btn a:hover {
  color: #f8f2ed;
}

.btn:hover.btn--3:hover, .btn:active.btn--3:hover, .btn:focus.btn--3:hover {
  background-color: linear-gradient(315deg, #ea2027 0%, #ee5a24 54%);
  color: #f8f2ed;
  border: none;
}

.btn:hover.btn--2:hover, .btn:active.btn--2:hover, .btn:focus.btn--2:hover {
  color: #f8f2ed;
  background-color: linear-gradient(315deg, #ea2027 0%, #ee5a24 54%);
}

.btn:hover.btn--1:hover, .btn:active.btn--1:hover, .btn:focus.btn--1:hover {
  color: #28231d;
  background-color: #f8f2ed;
}

.btn:hover.btn--5:hover, .btn:active.btn--5:hover, .btn:focus.btn--5:hover {
  color: #f8f2ed;
  background: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
}

.services {
  z-index: 0;
  flex-direction: column;
  gap: 5rem;
  width: 95%;
  max-width: 100%;
  min-height: auto;
  margin: auto;
  font-size: 1.3em;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1116px) {
  .services {
    flex-direction: row;
  }
}

.col-1-of-3, .col-2-of-3, .col-3-of-3 {
  background-color: #28231d;
  border-radius: 16px;
  flex: 2 auto;
  gap: 2rem;
  width: 100%;
  max-width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.card_details ul {
  color: #f2eded;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1rem;
  font-size: 1em;
  line-height: 2.4;
  list-style: none;
}

.card_heading-span {
  font-size: 1em;
}

.card_details li {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.card_details li:after {
  content: "";
  opacity: .5;
  background-color: #f2eded;
  width: 80%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.cta {
  background-color: #28231d;
  flex: auto;
  max-width: 100%;
  height: 45rem;
  padding: 3rem;
}

form {
  background-color: #28231d;
  border-radius: 18px;
  width: 90vw;
  max-width: 100%;
  min-height: auto;
  margin: auto;
}

@media only screen and (width >= 1116px) {
  form {
    width: 85vw;
  }
}

form label {
  color: #fef9f3;
  width: 70%;
  margin: auto;
  padding: 1.7rem 1.5rem;
  font-size: 1.7rem;
}

input, label {
  width: 70%;
  margin: auto;
  display: block;
}

textarea {
  color: #000;
  background-color: #fef9f3;
  border: none;
  border-radius: 12px;
  width: 50%;
  margin: 3rem auto auto;
  padding: 2rem;
  display: block;
}

@media only screen and (width >= 1116px) {
  textarea {
    width: 60%;
  }
}

input:focus, textarea:focus {
  caret-color: #fff;
  border: none;
  outline: 2px solid #fff;
}

input {
  color: #000;
  background: none;
  border: 0;
  border-bottom: .5px solid #f2eded;
  height: 4rem;
  padding: 1.7rem;
  font-size: 1.8rem;
}

.input-control input:focus {
  border-bottom: 1.5px solid #f2eded;
  outline: 0;
}

.optional-items {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: auto;
  display: flex;
}

.optional-items label {
  width: 100%;
  max-width: 100%;
}

#deadline {
  background-color: #fef9f3;
  border-radius: 8px;
  width: 85%;
  margin: 3rem auto;
}

@media only screen and (width >= 1116px) {
  #deadline {
    width: 100%;
  }
}

select {
  text-align: center;
  border: .4px solid #fff;
  padding: 1.3rem 1.8rem;
}

select:focus {
  border: none;
  outline: 1px solid #fff;
}

.error {
  text-align: center;
  color: #b22222;
  padding-top: 1rem;
  font-size: 1.7rem;
}

::placeholder {
  color: #fef9f3;
  opacity: .6;
  font-size: 1.2rem;
}

header {
  z-index: 1;
  background-image: url("london.9e915363.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 auto;
  width: 100vw;
  height: 100vh;
  position: relative;
}

@media (width >= 1116px) {
  #header-primary {
    background-position: center;
    animation: 4s ease-out forwards zoomPan;
  }
}

@keyframes zoomPan {
  0% {
    background-position: 0 100%;
    background-size: 130%;
  }

  100% {
    background-position: center;
    background-size: 100%;
  }
}

@media only screen and (width <= 1115px) {
  #header-primary {
    z-index: 1;
    box-sizing: border-box;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    width: calc(100vw - 1rem);
    max-width: 100%;
    margin: .5rem;
    padding: .5rem;
    position: relative;
    overflow: hidden;
  }

  #header-primary:before {
    content: "";
    pointer-events: none;
    z-index: -1;
    background-color: #000c;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.wrapper {
  color: #f8f2ed;
  z-index: 10;
  opacity: 0;
  background-color: #28231d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: transform 1s ease-out, opacity .5s ease-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(100vh);
}

.header-secondary {
  z-index: 1;
  z-index: 1;
  box-sizing: border-box;
  background-image: url("background.24f56ab3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 auto;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.header-secondary:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background-color: #fff9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 1115px) {
  .header-secondary .header-secondary {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    width: calc(100vw - 1rem);
    margin: .5rem;
    padding: .5rem;
  }
}

.header-tertiary {
  z-index: 1;
  z-index: 1;
  box-sizing: border-box;
  background-image: url("london-2.ffeddd07.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 auto;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.header-tertiary:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background-color: #ffffff4d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 1115px) {
  .header-tertiary {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    width: calc(100vw - 1rem);
    margin: .5rem;
    padding: .5rem;
  }
}

.wrapper.slide-up {
  opacity: 1;
  transform: translateY(0);
}

header nav {
  z-index: 1000;
  background-color: #0000;
  transition: background-color .3s;
}

header nav.nav-fixed {
  border-bottom: .5px solid #d3d3d3;
  background-color: #f8f2ee !important;
}

.wrapper-visible #header-primary nav {
  opacity: 0;
}

.nav-fixed {
  z-index: 1000;
  opacity: 1;
  width: 100%;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

header.header-quaternary {
  background-image: none;
  width: 100vw;
  max-width: 100%;
  height: auto;
}

.hero {
  opacity: 0;
  width: 85%;
  max-width: 100%;
  margin: auto;
  transition: opacity 4s ease-out, transform 4s ease-out;
  transform: translateY(50px);
}

.hero.show {
  opacity: 1;
  transform: translateY(0);
}

.hero p {
  text-align: center;
  color: #f8f2ed;
  line-height: 2.2;
}

.hero .slogan {
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 1.6rem;
  font-weight: 400;
}

@media only screen and (width >= 1116px) {
  .hero .slogan, .hero .short-intro p {
    font-size: 2.5rem;
  }
}

@media (width >= 1116px) {
  .start-intro {
    display: none;
  }
}

@media (width <= 1116px) {
  .start-intro {
    opacity: 0;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    margin-left: 1.5rem;
    transition: opacity 1s ease-in-out;
    display: flex;
  }
}

.start-intro p {
  text-align: left;
  color: #f8f2ed;
  z-index: 1000;
  width: 80%;
  margin: auto;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.9;
  position: absolute;
  top: 10rem;
}

.start-intro .btn {
  text-transform: uppercase;
  color: #f8f2ed;
  background-color: #ff9400;
  border: none;
  width: 14rem;
  height: 5rem;
  font-size: 1.4rem;
  font-weight: 600;
  position: absolute;
  bottom: 3rem;
}

.start-intro .btn:hover {
  background-color: #28231d;
}

nav {
  z-index: 10000000;
  color: #28231d;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

@media only screen and (width >= 1116px) {
  nav {
    height: 10rem;
    margin: auto;
    position: fixed;
  }
}

nav.nav-secondary {
  width: 100vw;
  max-width: 100%;
  background-color: #f8f2ee !important;
}

@media only screen and (width >= 1116px) {
  nav.nav-secondary {
    height: 10rem;
  }
}

a {
  text-decoration: none;
}

.item {
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.menu {
  z-index: 1000;
  flex-wrap: wrap;
  place-items: center;
  width: 100%;
  list-style-type: none;
  display: flex;
}

@media only screen and (width <= 1115px) {
  .menu {
    background-color: #f2eded;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3.5rem;
    width: 100vw;
    height: 100vh;
    transition: left .4s ease-in-out;
    position: fixed;
    top: 0;
    left: -100%;
  }

  .menu.active {
    height: 100vh;
    left: 0;
  }
}

.menu li a {
  color: #28231d;
  width: 100%;
  padding: 1rem;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  display: block;
}

@media only screen and (width >= 1116px) {
  .menu li a {
    font-size: 1.6rem;
  }
}

@media only screen and (width <= 1115px) {
  .menu li a {
    text-transform: uppercase;
  }

  .menu li a[href^="mailto:"] {
    text-transform: none;
  }
}

.menu li:not(.toggle) {
  text-align: left;
}

.menu li a.active {
  color: #0000;
  background: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%) text;
  font-weight: bold;
}

footer ul li a.active {
  color: #cc5de8;
}

li a strong {
  opacity: .7;
  color: #cc5de8;
}

.item {
  text-align: center;
  width: 100%;
  padding-left: 1rem;
  display: none;
}

@media only screen and (width >= 1116px) {
  .item {
    padding-left: 0;
    display: block;
  }
}

.menu.active .item {
  display: block;
}

.toggle {
  z-index: 1100;
  text-align: center;
  background-color: #f8f2ed;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: 1rem;
  margin-left: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.toggle i {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
  -webkit-background-clip: text;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 30px;
  display: inline-flex;
}

@media (width >= 1116px) {
  .item {
    width: auto;
    display: block;
    position: relative;
  }

  .toggle {
    display: none;
  }
}

i {
  color: #3730a3;
}

.toggle i {
  font-size: 30px;
}

@media only screen and (width <= 1115px) {
  .menu {
    width: 100vw;
    max-width: 100%;
    height: auto;
    margin: 0;
  }

  .item a {
    color: inherit;
    border-bottom: 2px solid #0000;
    text-decoration: none;
    transition: border-color .3s ease-in-out;
    display: inline-block;
    position: relative;
  }

  .item a:hover, .item a:focus, .item a:active {
    color: #f2eded;
    background: #28231d 0 100% / 100% 100% no-repeat;
    border-bottom: 2px solid #0000;
    transition: background-position .3s ease-in-out;
  }

  .item a:hover:before, .item a:focus:before, .item a:active:before {
    background-position: 100% 100%;
  }

  .item:last-child a {
    border-bottom: none;
  }
}

.social-media-links {
  justify-content: flex-start;
  gap: 1rem;
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
}

@media only screen and (width <= 1115px) {
  .social-media-links {
    gap: 2rem;
    margin-left: 2rem;
    margin-right: auto;
    padding: 0;
  }
}

.social-media-links a {
  background-color: #28231d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  text-decoration: none;
  display: flex;
}

footer {
  color: #f8f2ed;
  background-color: #28231d;
  flex-flow: column;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
}

@media only screen and (width >= 1116px) {
  footer {
    flex-direction: row;
    height: 50rem;
  }
}

.footer__menu {
  flex-direction: column;
  margin: auto;
  display: flex;
}

.footer-col-2 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.footer-col-3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem;
  display: flex;
}

.social-media {
  margin: auto;
  padding-bottom: 1.2rem;
}

@media only screen and (width <= 1116px) {
  .social-media svg {
    width: 17px;
    height: 17px;
  }
}

footer a {
  color: #f8f2ed;
  font-size: 1.3em;
}

.footer-p {
  color: #f8f2ed;
  text-align: center;
}

.footer-col-1 {
  margin: auto;
}

.footer-col-2 {
  text-align: center;
  margin: auto;
}

.footer-col-2 li {
  justify-content: center;
  padding: 2rem 1rem;
  list-style: none;
  display: inline-block;
}

.footer-col-3 {
  text-align: center;
  margin: auto;
}

.footer-col-3 li {
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
}

.footer-col-3 h6 {
  text-transform: uppercase;
}

.logo-light {
  text-align: center;
}

.tagline {
  z-index: 0;
  color: #28231d;
  background-color: #f8f2ed;
  flex: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 50vh;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  position: relative;
}

.tagline-content {
  flex: 1;
  justify-content: center;
  align-self: center;
}

.tagline h1 {
  width: 90%;
  max-width: 90%;
  font-size: 3rem;
}

@media only screen and (width >= 1116px) {
  .tagline h1 {
    font-size: 4.5rem;
  }
}

.tagline p {
  width: 90%;
  max-width: 90%;
}

@media only screen and (width >= 1116px) {
  .tagline p {
    width: 75%;
    max-width: 75%;
  }
}

.promo {
  color: #28231d;
  background-color: #f8f2ed;
  align-items: center;
  height: auto;
  margin: auto;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 2.3;
}

@media (width >= 1116px) {
  .promo {
    width: 100%;
    height: auto;
    font-size: 3rem;
    line-height: 1.5;
  }
}

.portfolio-sample-1, .portfolio-sample-2, .portfolio-sample-3, .portfolio-sample-4 {
  border: none;
  width: 100vw;
  max-width: 100%;
  height: auto;
  margin: auto;
}

@media only screen and (width >= 1116px) {
  .portfolio-sample-1, .portfolio-sample-2, .portfolio-sample-3, .portfolio-sample-4 {
    flex-wrap: nowrap;
    flex: 100%;
    width: 100%;
  }
}

.portfolio-sample-1 {
  background-color: #f2eded;
}

.num__container {
  color: #f8f2ee;
  background: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
  border: 6px solid #8136e3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin: 3rem;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .num__container {
    border: 12px solid #8136e3;
    width: 13rem;
    height: 13rem;
  }
}

.num__container--light {
  background: linear-gradient(315deg, #ea2027 0%, #ee5a24 54%);
  border-color: #ee5a24;
}

.samples {
  width: 100vw;
  max-width: 100%;
  padding: 0;
}

section {
  z-index: 100;
}

.portfolio-sample-1 {
  border: none;
}

.portfolio-sample-2 {
  background-color: #f2eded;
  border: none;
}

.portfolio-sample-3 {
  border: none;
}

.portfolio-sample-5, .portfolio-sample-6, .portfolio-sample-7, .portfolio-sample-8, .portfolio-sample-9 {
  height: 300px;
}

@media only screen and (width >= 1116px) {
  .portfolio-sample-5, .portfolio-sample-6, .portfolio-sample-7, .portfolio-sample-8, .portfolio-sample-9 {
    width: 100vw;
    max-width: 100%;
    min-height: 800px;
  }
}

.portfolio-sample-5, .portfolio-sample-6, .portfolio-sample-7, .portfolio-sample-1, .portfolio-sample-2, .portfolio-sample-3, .portfolio-sample-4, .portfolio-sample-8, .portfolio-sample-9 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.portfolio-sample-5 {
  background-image: url("dev-sample-1.39a99d45.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio-sample-6 {
  background-image: url("dev-sample-2.7d585e7c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio-sample-7 {
  background-image: url("dev-sample-3.b37f6977.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio-sample-8 {
  background-color: #f8f2ed;
  background-image: url("design-1.1c65d91b.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio-sample-9 {
  background-color: #f8f2ed;
  background-image: url("design-2.fd03a16b.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.samples img {
  width: 80%;
}

.portfolio {
  color: #28231d;
  background-color: #f2eded;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .portfolio {
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    height: 600px;
  }
}

.container {
  opacity: .7;
  background: url("profile-pic.b7550d29.jpeg") top / cover no-repeat;
}

@media only screen and (width <= 1116px) {
  .container {
    background-position: top;
    background-size: cover;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 3rem auto 0;
  }
}

@media only screen and (width >= 1116px) {
  .container {
    max-height: auto;
    border-radius: 0;
    width: 400px;
    height: 600px;
  }
}

.text-container {
  flex-direction: column;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .text-container {
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 800px;
  }
}

.portfolio p {
  color: #28231d;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.9;
}

@media only screen and (width >= 1116px) {
  .portfolio p {
    font-size: 1.8rem;
  }
}

.desktop, .tablet, .phone {
  align-items: center;
  max-width: 100%;
  height: auto;
}

.desktop {
  width: 800px;
}

.phone {
  width: auto;
  height: 60rem;
}

.tablet {
  width: 100rem;
}

.phone {
  align-items: center;
}

.intro {
  color: #28231d;
  background-color: #f2eded;
  flex-direction: column;
  align-self: center;
  width: 100vw;
  max-width: 100%;
  height: auto;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .intro {
    flex-direction: row;
  }
}

.intro p {
  background-color: #f2eded;
  width: 90vw;
  max-width: 100%;
  margin: auto;
  padding: 2rem;
  font-size: 2rem;
}

@media only screen and (width >= 1116px) {
  .intro p {
    width: 75%;
    max-width: 75%;
    font-size: 1.9rem;
    line-height: 3.3;
  }
}

.introduction-container {
  width: 100vw;
  max-width: 100%;
  height: auto;
  margin: auto;
  padding: 4rem;
}

.introduction-container p {
  color: #28231d;
  background-color: #f2eded;
  width: 90%;
  padding: 2rem;
  font-size: 2rem;
  font-style: italic;
  line-height: 1.9;
}

.banner {
  -o-background-size: contain;
  -webkit-background-size: contain;
  background: url("bckgpic-sm.474cb339.png") center / contain no-repeat;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: 20rem;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .banner {
    -o-background-position: fixed;
    -webkit-background-position: fixed;
    background: url("bckgpic-lg.50433212.png") center no-repeat fixed;
    height: 100vh;
  }
}

.faq {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 80%;
  height: auto;
  margin: 1rem auto 5rem;
  display: flex;
}

.faq h5 {
  text-align: left;
}

.faq-content {
  border-bottom: .1px solid #3730a3;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0 auto;
}

.faq-content div {
  margin: 10% auto auto;
  display: block;
}

@media only screen and (width >= 1116px) {
  .faq-content div {
    margin-top: 5%;
  }
}

.acc-btn {
  cursor: pointer;
  background: inherit;
  text-align: right;
  border: none;
  outline: none;
  width: 100%;
  padding: 1.6rem 2rem;
  font-size: 1.6rem;
  transition: all .5s linear;
}

.acc-btn i {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(109.6deg, #e46be8 11.2%, #571be2 96.7%);
  -webkit-background-clip: text;
}

.answer {
  width: 100%;
  max-width: 100%;
  max-height: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 1.6rem;
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

fieldset {
  color: #28231d;
  border: none;
}

legend {
  margin: 0 auto;
}

.our-story {
  color: #28231d;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  padding: 4rem;
  font-size: 1.3em;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .our-story {
    flex-direction: row;
  }
}

.our-story p {
  color: #28231d;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.box {
  margin-left: 5%;
  transition: transform 1.3s, opacity 1.3s;
}

.box.left {
  opacity: 0;
  transform: translateX(-100px);
}

.box.right {
  opacity: 0;
  transform: translateX(100px);
}

.box.active {
  opacity: 1;
  transform: translateX(0);
}

.scroll-reveal.active {
  opacity: 1;
}

@media only screen and (width >= 1116px) {
  .box {
    margin-left: calc(50% - 700px);
    transition: transform 1.3s, opacity 1.3s;
  }

  .box.left {
    opacity: 0;
    transform: translateX(-700px);
  }

  .box.right {
    opacity: 0;
    transform: translateX(200px);
  }

  .box.active {
    opacity: 1;
    transform: translateX(0);
  }
}

.privacy {
  background-color: #f2eded;
  width: 100vw;
  max-width: 100%;
  margin: auto;
  padding: 2rem;
}

@media only screen and (width >= 1116px) {
  .privacy {
    padding-top: 10rem;
    padding-left: 3rem;
  }
}

.privacy h1:first-child {
  text-align: center;
  font-size: 2.5rem;
}

.privacy h1 {
  text-align: left;
  text-align: center;
  color: #28231d;
  opacity: .8;
  padding-top: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 2rem;
  text-decoration: underline;
}

.privacy p {
  text-align: left;
  color: #28231d;
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.6rem;
  line-height: 2.4;
}

@media only screen and (width >= 1116px) {
  .privacy p {
    font-size: 1.8rem;
  }
}

.privacy address {
  text-align: center;
  padding-top: 5rem;
  line-height: 2.4;
}

.privacy address a {
  color: #ff9400;
}

.privacy address span {
  text-decoration: underline;
}

hr {
  opacity: .5;
  background-color: #ff9400;
  border: none;
  width: 70%;
  height: 1px;
  margin: auto;
}

.design-quote {
  width: 100vw;
  max-width: 100%;
  height: 25rem;
  min-height: auto;
  padding: 3rem;
  display: flex;
}

.design-quote blockquote {
  justify-content: center;
  font-size: 2.5rem;
}

@media only screen and (width >= 1116px) {
  .design-quote blockquote {
    font-size: 3.3rem;
  }
}

.our-story {
  background-color: #f2eded;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  height: auto;
  display: flex;
}

@media only screen and (width <= 958px) {
  .our-story {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.introduction {
  color: #28231d;
  justify-self: flex-start;
  width: 80%;
  max-width: 100%;
  font-size: 1.8rem;
  line-height: 1.9;
}

.dev-services {
  background-color: #f8f2ed;
  flex: 2 auto;
  justify-content: center;
  place-items: center;
  width: 100vw;
  max-width: 100%;
  height: auto;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (width >= 959px) {
  .dev-services {
    flex-direction: row;
    padding-bottom: 1rem;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.dev-services p {
  background-color: #f2eded;
  width: 100%;
  max-width: 100%;
}

.dev-services-container {
  flex-direction: column;
  align-content: center;
  gap: 2rem;
  width: 100vw;
  max-width: 100%;
  height: auto;
  display: flex;
}

@media only screen and (width >= 959px) {
  .dev-services-container {
    flex-direction: row;
    justify-content: center;
  }

  .col-first, .col-second, .col-third, .col-fourth {
    flex-grow: 2;
    width: 25%;
    max-width: 25%;
    height: auto;
    max-height: 50rem;
    margin-bottom: 3rem;
  }
}

.col-first > p, .col-second > p, .col-third > p, .col-fourth > p {
  color: #f2eded;
  text-align: left;
  background-color: #28231d;
  border-radius: 9px;
  align-content: center;
  width: 90vw;
  height: 100%;
  max-height: 100%;
  margin-top: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.description {
  background-color: #f2eded;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: auto;
  display: flex;
}

@media only screen and (width >= 959px) {
  .description {
    flex-flow: row;
  }
}

.col-dev {
  flex-flow: column wrap;
  padding: 2rem;
  font-size: 2rem;
  display: flex;
}

@media only screen and (width >= 959px) {
  .col-dev {
    width: 55%;
  }
}

figure {
  text-align: center;
  padding-top: 2rem;
}

svg {
  height: 100%;
  margin: auto;
  display: inline-block;
}

.ul-dev {
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  list-style: none;
}

.ul-dev li {
  padding: 1.5rem 1.9rem;
  list-style: none;
}

.li-dev {
  font-size: 1.4rem;
}

@media only screen and (width >= 1116px) {
  .li-dev {
    font-size: 1.6rem;
  }
}

.tick-icon {
  vertical-align: middle;
  width: 32px;
  height: 32px;
}

.desc_img-container {
  opacity: .8;
  background: url("artboard.d9457bfc.png") center / cover no-repeat;
  width: 100%;
  max-width: 100%;
  height: 50rem;
  min-height: 100%;
}

@media only screen and (width >= 959px) {
  .desc_img-container {
    opacity: .6;
    background-position: center;
    background-repeat: no-repeat;
    align-self: center;
    width: 45%;
    margin-left: 5rem;
  }
}

.seo-banner {
  -webkit-background-size: contain;
  -o-background-size: contain;
  background: url("seo.0b3c919f.png") center / contain no-repeat;
  width: 100vw;
  max-width: 100%;
  height: 30rem;
  min-height: auto;
  margin-top: 0;
  padding-top: 0;
}

@media only screen and (width >= 959px) {
  .seo-banner {
    -webkit-background-size: cover;
    -o-background-position: fixed;
    -webkit-background-position: fixed;
    -o-background-size: cover;
    background: url("seo-2.25924318.png") center / cover no-repeat fixed;
    height: 100vh;
  }
}

.seo {
  color: #333;
  background-color: #f8f2ed;
  flex-flow: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  margin: auto;
  font-size: 2rem;
  display: flex;
}

@media only screen and (width >= 959px) {
  .seo {
    flex-direction: row;
  }
}

.seo-desc {
  background-color: #f2eded;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@media only screen and (width >= 1116px) {
  .seo-desc {
    flex-direction: row;
    gap: 3rem;
    width: 100vw;
    max-width: 100%;
  }
}

.seo img {
  justify-content: center;
  width: 100px;
  transition: all 2s ease-in;
  transform: translateY(-10%);
}

.img-wrapper {
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contact {
  background-color: #f2eded;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  display: flex;
}

.contact-container p {
  text-align: left;
}

.contact a {
  color: #28231d;
  margin-left: 2rem;
  font-size: 1.4rem;
  text-decoration: underline;
}

.social_link-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
}

.drop-the-line {
  background: #28231d;
  width: 100vw;
  max-width: 100%;
  height: auto;
}

@media only screen and (width >= 1116px) {
  .drop-the-line {
    padding-top: 10rem;
  }
}

.drop-the-line p {
  color: #f8f2ed;
  max-width: 90%;
  margin: auto;
  padding-bottom: 5rem;
  font-size: 1.6rem;
}

@media only screen and (width >= 1116px) {
  .drop-the-line p {
    width: 60%;
  }
}

.drop-the-line h1 {
  color: #f8f2ed;
  padding-top: 3rem;
  font-size: 3rem;
}

.drop-the-line h3 {
  font-size: 2rem;
}

.column-1, .column-2 {
  width: 100vw;
  max-width: 100%;
  height: auto;
}

@media only screen and (width >= 1116px) {
  .column-1, .column-2 {
    width: 60%;
  }
}

.column-1 p, .column-2 p {
  text-align: center;
}

.img-desc {
  flex: auto;
  width: 100%;
  max-width: 100%;
}

@media only screen and (width >= 1116px) {
  .img-desc {
    width: 80%;
  }
}
/*# sourceMappingURL=index.4a997795.css.map */
