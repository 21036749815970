.services {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  min-height: auto;
  max-width: 100%;
  width: 95%;
  font-size: 1.3em;
  gap: 5rem;
  margin: auto;
  @media only screen and (min-width: $bp-medium) {
    flex-direction: row;
  }
}

.col-1-of-3,
.col-2-of-3,
.col-3-of-3 {
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-radius: 16px;
  background-color: $color-tertiary-1;
  flex: 2 1 auto;
  width: 100%;
  max-width: 100%;
  gap: 2rem;
}

.card_details ul {
  list-style: none;
  font-size: 1em;
  line-height: 2.4;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1rem;
  color: $color-primary-dark;
}

.card_heading-span {
  font-size: 1em;
}

.card_details li {
  text-align: center;
  position: relative;
  padding: 1rem;
}

.card_details li::after {
  content: "";
  display: block;
  height: 1px;
  left: 25px;
  width: 80%;
  background-color: $color-primary-dark;
  opacity: 0.5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
