/* Typewriter effect */
.demo-1 {
  margin: auto;
  text-align: center;
  font-family: "Playfair Display, serif";
  font-weight: 400;
  position: relative;
  z-index: 10;
  top: 50%;
  font-style: normal;
  color: $color-primary-dark;
  text-transform: uppercase;
  padding: 2rem;
  font-size: 4rem;
  line-height: 1.5;
  opacity: 1;
  transition: opacity 1s ease-out;
}

.demo-1.fade-out {
  display: none;
}

@media only screen and (min-width: $bp-medium) {
  .demo-1 {
    font-size: 7rem;
    padding-left: 4rem;
    line-height: 2.2;
    top: 50%;
  }
}

.scroll-reveal.opacity-only {
  opacity: 0;
  transition: opacity 2s ease-out;
}

.scroll-reveal.opacity-only.reveal {
  opacity: 1;
}
