*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: $color-primary-light;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: $color-primary-light;
}

body::-webkit-scrollbar-thumb {
  background: $color-gradient;
  border-radius: 10px;
  border: 1px solid $color-primary-light;
}

/* Custom scrollbar styling for WebKit browsers (Chrome, Safari) */

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: $color-primary-light;
}

body::-webkit-scrollbar-thumb {
  background: $color-gradient;
  border-radius: 10px;
  border: 1px solid $color-primary-light;
}

::selection {
  background-color: $color-secondary;
  color: $color-tertiary-2;
}
