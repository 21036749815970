.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 3rem;
}

.u-margin-bottom-big {
  margin-bottom: 5rem;
}

.u-margin-top-small {
  margin-top: 3rem;
}

.u-margin-top-big {
  margin-top: 5rem;
}
