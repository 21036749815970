form {
  width: 90vw;
  max-width: 100%;
  min-height: auto;
  margin: auto;
  background-color: $color-tertiary-1;
  border-radius: 18px;

  @media only screen and (min-width: $bp-medium) {
    width: 85vw;
  }
}

form label {
  color: #fef9f3;
  padding: 1.7rem 1.5rem;
  width: 70%;
  margin: auto;
  font-size: 1.7rem;
}

input,
label {
  display: block;
  width: 70%;
  margin: auto;
}

textarea {
  display: block;
  width: 50%;
  margin: auto;
  margin-top: 3rem;
  border-radius: 12px;
  border: none;
  padding-top: 2rem;
  background-color: #fef9f3;
  color: $color-tertiary-2;
  padding: 2rem;

  @media only screen and (min-width: $bp-medium) {
    width: 60%;
  }
}

input:focus,
textarea:focus {
  outline: 2px solid #fff;
  border: none;
  caret-color: #fff;
}

input {
  font-size: 1.8rem;
  color: $color-tertiary-2;
  padding: 1.7rem;
  height: 4rem;
  border: 0;
  border-bottom: 0.5px solid $color-primary-dark;
  background-color: transparent;
  background: none;
}

.input-control input:focus {
  outline: 0;
  border-bottom: 1.5px solid $color-primary-dark;
}

.optional-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: auto;
  text-align: center;
}

.optional-items label {
  width: 100%;
  max-width: 100%;
}

#deadline {
  background-color: #fef9f3;
  border-radius: 8px;
  margin: 3rem auto;
  width: 85%;

  @media only screen and (min-width: $bp-medium) {
    width: 100%;
  }
}
select {
  text-align: center;
  padding: 1.3rem 1.8rem;
  border: 0.4px solid #fff;
}

select:focus {
  outline: 1px solid #fff;
  border: none;
}

.error {
  text-align: center;
  font-size: 1.7rem;

  padding-top: 1rem;
  color: #b22222;
}

::placeholder {
  color: #fef9f3;
  opacity: 0.6;
  font-size: 1.2rem;
}
