.design-quote {
  display: flex;
  height: auto;
  padding: 3rem;
  width: 100vw;
  max-width: 100%;
  height: 25rem;
  min-height: auto;
}

.design-quote blockquote {
  font-size: 2.5rem;
  justify-content: center;

  @media only screen and (min-width: $bp-medium) {
    font-size: 3.3rem;
  }
}

.our-story {
  display: flex;
  flex-direction: column;
  background-color: $color-primary-light;
  height: auto;
  width: 100vw;
  max-width: 100%;
  background-color: $color-primary-dark;

  @media only screen and (max-width: 958px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.introduction {
  justify-self: flex-start;
  width: 80%;
  max-width: 100%;
  font-size: 1.8rem;
  line-height: 1.9;
  color: $color-tertiary-1;
}
