nav {
  display: flex;
  width: 100%;
  z-index: 10000000;
  justify-content: center;
  align-items: center;
  color: $color-tertiary-1;

  @media only screen and (min-width: $bp-medium) {
    position: fixed;
    height: 10rem;
    margin: auto;
  }
}

nav.nav-secondary {
  background-color: #f8f2ee !important;
  width: 100vw;
  max-width: 100%;

  @media only screen and (min-width: $bp-medium) {
    height: 10rem;
  }
}

a {
  text-decoration: none;
}

.item {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}

/* Mobile menu */

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  width: 100%;
  list-style-type: none;
  z-index: 1000;

  @media only screen and (max-width: 1115px) {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100vw;
    height: 100vh;
    background-color: $color-primary-dark;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3.5rem;
    transition: left 0.4s ease-in-out;
  }
}

.menu.active {
  @media only screen and (max-width: 1115px) {
    left: 0;
    height: 100vh;
  }
}

.menu li a {
  display: block;
  color: $color-tertiary-1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  width: 100%;
  padding: 1rem 1rem;

  @media only screen and (min-width: $bp-medium) {
    font-size: $default-font-size;
  }
}

@media only screen and (max-width: 1115px) {
  .menu li a {
    text-transform: uppercase;
  }

  .menu li a[href^="mailto:"] {
    text-transform: none;
  }
}

.menu li:not(.toggle) {
  text-align: left;
}

.menu li a.active {
  background: $color-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

footer ul li a.active {
  color: #cc5de8;
}

li a strong {
  opacity: 0.7;
  color: #cc5de8;
}

.item {
  width: 100%;
  text-align: center;
  display: none;
  padding-left: 1rem;

  @media only screen and (min-width: $bp-medium) {
    display: block;
    padding-left: 0;
  }
}

.menu.active .item {
  display: block;
}

.toggle {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
  margin-top: 1rem;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: $color-primary-light;
  border-radius: 50%;
  text-align: center;
}

.toggle i {
  margin: 0;
  padding: 0;
  font-size: 30px;
  background-image: $color-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media all and (min-width: $bp-medium) {
  .item {
    position: relative;
    display: block;
    width: auto;
  }

  .toggle {
    display: none;
  }
}

i {
  color: #3730a3;
}

.toggle i {
  font-size: 30px;
}

@media only screen and (max-width: 1115px) {
  .menu {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    height: auto;
  }

  .item a {
    display: inline-block;
    text-decoration: none;
    position: relative;
    color: inherit;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
  }

  .item a:hover,
  .item a:focus,
  .item a:active {
    border-bottom: 2px solid transparent;
    background: $color-tertiary-1;
    color: $color-primary-dark;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    transition: background-position 0.3s ease-in-out;
  }

  .item a:hover::before,
  .item a:focus::before,
  .item a:active::before {
    background-position: 100% 100%;
  }

  .item:last-child a {
    border-bottom: none;
  }
}

.social-media-links {
  display: flex;
  margin-left: auto;
  margin-right: 2rem;
  gap: 1rem;
  justify-content: flex-start;

  @media only screen and (max-width: 1115px) {
    padding: 0;
    margin-left: 2rem;
    margin-right: auto;
    gap: 2rem;
  }
}

.social-media-links a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $color-tertiary-1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
