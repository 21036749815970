.column-1,
.column-2 {
  max-width: 100%;
  width: 100vw;
  height: auto;
  @media only screen and (min-width: $bp-medium) {
    width: 60%;
  }
}

.column-1 p,
.column-2 p {
  text-align: center;
}
.img-desc {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;

  @media only screen and (min-width: $bp-medium) {
    width: 80%;
  }
}
